<script lang="ts">
	import { beforeNavigate, onNavigate, afterNavigate } from '$app/navigation';
	import { PrismicPreview } from '@prismicio/svelte/kit';
	import { repositoryName } from '$lib/prismicio';
	import SiteHeader from '$lib/components/SiteHeader.svelte';
	import { onMount, onDestroy } from 'svelte';
	import { getScrollDistance, navIndicatorBlur, navIndicatorHover, updateNavigationActiveState } from '$lib/helpers/app.helpers';
	import { page } from '$app/stores';
	import { PrismicLink } from '@prismicio/svelte';
	import SiteFooter from '$lib/components/SiteFooter.svelte';
	import { ClearColorTrigger } from '$lib/helpers/color.trigger';
	import { hideFooter } from '$lib/variable.store';

    export let data;

	let showMenu = false;
	let hideFooterString = '';
	let hideFooterBool = false;
	let isInsightsOrProject: boolean = false;

	const unsubscribe = hideFooter.subscribe(value => {
		hideFooterString = value;
		hideFooterBool = (hideFooterString == 'true') ? true : false;
	});

	onNavigate((navigation) => {
		if (!document.startViewTransition) return;

		return new Promise((resolve) => {
			document.startViewTransition(async () => {
				resolve();
				await navigation.complete;
			});
		});
	});

	beforeNavigate(() => {
		showMenu = false;
		ClearColorTrigger();
	});

	afterNavigate((navigation) => {
		updateNavigationActiveState(navigation);
		const nextPath = navigation.to?.url.pathname;
		isInsightsOrProject = (nextPath?.includes('/insights')) ? true : ((nextPath?.includes('/work')) ?  true : false);
	});

	onMount(() => {
		window.addEventListener('scroll', (event) => {
			getScrollDistance();
		});

		window.addEventListener('load', (event) => {
			getScrollDistance();
		});
	});

	onDestroy(() => {
		unsubscribe();
	});

	function toggleMenu() {
		showMenu = !showMenu;
	}
</script>

<svelte:head>
	{#if $page.data.meta_title}
		<meta name="og:title" content={$page.data.meta_title} />
		<title>{$page.data.meta_title}</title>
	{:else}
		<title>{$page.data.title} | Limegreentangerine</title>
	{/if}
	{#if $page.data.meta_description}
		<meta name="description" content={$page.data.meta_description} />
	{/if}
	{#if $page.data.meta_image}
		<meta name="og:image" content={$page.data.meta_image} />
		<meta name="twitter:card" content="summary_large_image" />
	{/if}

	<style>
		@import '/src/styles/app.scss';
	</style>

	<!-- Google tag (gtag.js) -->
	<script async src="https://www.googletagmanager.com/gtag/js?id=G-F6HWD1C0B4"></script>
	<script>
		window.dataLayer = window.dataLayer || [];
		function gtag(){dataLayer.push(arguments);}
		gtag('js', new Date());
		gtag('config', 'G-F6HWD1C0B4');
	</script>
</svelte:head>
<main>
	<SiteHeader />

	{#if !isInsightsOrProject}
		{@html `
			<script type="application/ld+json">
 				"@context": "https://schema.org/",
                "@type": "WebSite",
				"name": "Limegreentangerine",
				"url": "https://www.limegreentangerine.co.uk"
			</script>
		`}
	{/if}

	{#if data.navigation && data.navigation.data.links.length > 0}
		<button class="navigation-toggle" on:click|preventDefault={toggleMenu} class:active={showMenu} aria-label="Open & Close Navigation">
			<span></span>
			<span></span>
		</button>

		<nav class="main-nav" class:active={showMenu}>
			<div class="main-nav__background"></div>

			<div class="main-nav__wrapper">
				<ul class="nav">
					{#each data.navigation.data.links as link,index}
						<li class="nav-item"
							on:mouseover={navIndicatorHover}
							on:focus={navIndicatorHover}
							on:mouseleave={navIndicatorBlur}
							on:blur={navIndicatorBlur}
						>
							<PrismicLink
								field={link.link}
								class="nav-link"
								id="nav-link-{index}"
							>
								{link.label}
							</PrismicLink>
						</li>
					{/each}
				</ul>

				<div class="main-nav__indicator"></div>
			</div>
		</nav>
	{/if}

	<slot />

	<SiteFooter data={data.footer.data} year={data.year} hideFooter={hideFooterBool} />
</main>

<PrismicPreview {repositoryName} />
