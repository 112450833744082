<header class="main-header">
    <div class="container">
        <div class="d-flex justify-content-between">
            <a href="/" class="main-header__logo--container" aria-label="Limegreentangerine Home">
                <svg class="main-header__logo" width="61px" height="32px" viewBox="0 0 61 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Visuals" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="work_case_study_ecube-copy" transform="translate(-20.000000, -20.000000)" fill="#000000" fill-rule="nonzero">
                            <g id="logo" transform="translate(20.000000, 20.000000)">
                                <polygon id="Path" points="5.85534941 10.6442105 16.9222309 10.6442105 16.9222309 14.8547368 0.426952561 14.8547368 0.426952561 0 5.85534941 0"></polygon>
                                <rect id="Rectangle" x="11.6293745" y="0" width="5.42161982" height="7.60589474"></rect>
                                <polygon id="Path" points="27.2571936 0 31.2963004 8.48842105 35.4167315 0 42.5664926 0 42.5664926 14.8547368 37.2600822 14.8547368 37.2600822 5.57136842 32.821131 14.8547368 29.4122875 14.8547368 25.0072214 5.57136842 25.0072214 14.8547368 19.7889123 14.8547368 19.7889123 0"></polygon>
                                <polygon id="Path" points="51.3427397 3.59747368 51.3427397 5.49052632 60.5594934 5.49052632 60.5594934 9.18905263 51.3427397 9.18905263 51.3427397 11.2235789 61 11.2235789 61 14.8547368 45.9346739 14.8547368 45.9346739 0 60.8644595 0 60.8644595 3.59747368"></polygon>
                                <path d="M0,24.4884211 L0,24.0909474 C0,20.8168421 1.91112099,16.6871579 9.60304411,16.6871579 L10.3078547,16.6871579 C18.1014332,16.6871579 19.687257,20.0016842 19.687257,22.1574737 L19.687257,22.2383158 L14.0013332,22.2383158 C13.8386846,21.8610526 13.3168537,20.6618947 9.96222642,20.6618947 C6.60759916,20.6618947 5.62493056,22.4404211 5.62493056,24.1987368 L5.62493056,24.4008421 C5.62493056,26.0783158 6.58726808,28.0522105 9.96222642,28.0522105 C13.3371848,28.0522105 13.960671,26.7115789 14.0623264,26.2534737 L9.78602378,26.2534737 L9.78602378,23.4778947 L19.8702366,23.4778947 L19.8702366,31.7844211 L16.0344406,31.7844211 C15.9124542,31.2656842 15.593934,30.3696842 15.309299,29.8846316 C14.8484613,30.4033684 13.0389957,32 9.22353072,32 L8.88467948,32 C2.01277636,32 0.00677702478,27.8837895 0.00677702478,24.4951579" id="Path"></path>
                                <g id="Group" transform="translate(21.964337, 16.922947)">
                                    <path d="M5.42161982,14.848 L0,14.848 L0,0 L10.8974558,0 C16.4546162,0 17.9048995,2.07494737 17.9048995,5.00547368 L17.9048995,5.26821053 C17.9048995,8.21894737 16.4004,10.4151579 10.8974558,10.4151579 L5.4148428,10.4151579 L5.4148428,14.848 L5.42161982,14.848 Z M10.402733,6.62905263 C11.8665704,6.62905263 12.2867459,5.98905263 12.2867459,5.21431579 L12.2867459,5.17389474 C12.2867459,4.45305263 11.8665704,3.79957895 10.402733,3.79957895 L5.42161982,3.79957895 L5.42161982,6.63578947 L10.402733,6.63578947 L10.402733,6.62905263 Z" id="Shape"></path>
                                    <polygon id="Path" points="18.0743251 14.848 12.4290634 14.848 7.25141651 7.01978947 12.8966781 7.01978947"></polygon>
                                </g>
                                <polygon id="Path" points="47.4256194 31.7776842 42.1395401 31.7776842 42.1395401 16.9229474 47.6628152 16.9229474 55.7206977 25.2294737 55.7206977 16.9229474 61 16.9229474 61 31.7776842 55.802022 31.7776842 47.4256194 23.2151579"></polygon>
                            </g>
                        </g>
                    </g>
                </svg>
            </a>

            <a href="/contact" class="main-header__contact--container">
                <span class="main-header__contact--text">Let’s talk</span>

                <svg class="main-header__contact--icon" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M17.1973 33.709C26.4775 33.709 34 26.186 34 16.9062C34 7.62622 26.4775 0.103271 17.1973 0.103271C7.91748 0.103271 0.394531 7.62622 0.394531 16.9062C0.394531 26.186 7.91748 33.709 17.1973 33.709ZM17.8438 14.2595L19.9902 16.406H13.1973V17.406H19.9902L17.8438 19.5525L18.5508 20.2595L21.5508 17.2595L21.9043 16.906L21.5508 16.5525L18.5508 13.5525L17.8438 14.2595Z"
                    />
                </svg>
            </a>
        </div>
    </div>
</header>
