<script lang="ts">
	import { enhance } from '$app/forms';
    import { fade } from 'svelte/transition';

    export let show;

    let emailAddress: string = '';
    let submitted: boolean = false;
    let submitError: boolean = false;
    let submitErrorMessage: string = '';

    function handleSubmit(event: { preventDefault: () => void; }) {
        submitError = false;
        submitErrorMessage = '';

        if (emailAddress.length < 1) {
            event.preventDefault();
            submitError = true;
            submitErrorMessage = 'Email address is required';
        }
    }
</script>

<div class="subscribe" class:open={show}>
    <div class="subscribe__wrapper">
        <div class="container">
            <div class="position-relative">
                <!-- {#if submitted}
                    <div class="text-center" transition:fade><h5>Thank you for subscribing</h5></div>
                {:else} -->
                    <form
                        transition:fade
                        on:submit={handleSubmit}
                        method="post"
                        action="https://limegreentangerine.us6.list-manage.com/subscribe/post?u=11c2d2bcf88a267402d2a87e5&amp;id=97b2a66f6f&amp;f_id=00fc18e2f0"
                    >
                        <div class="d-flex align-items-center">
                            <input type="email" name="EMAIL" bind:value={emailAddress} class="subscribe__input" placeholder="Add your email address" />
                            <button type="submit" class="subscribe__button">Subscribe</button>
                        </div>
                    </form>

                    {#if submitError}
                        <p class="subscribe__error" transition:fade><small>{submitErrorMessage}</small></p>
                    {/if}
                <!-- {/if} -->
            </div>
        </div>
    </div>
</div>
