<script lang="ts">
	import { scrollToTop, hideMenu, showMenu, scrollToElement } from '$lib/helpers/app.helpers';
    import * as prismic from '@prismicio/client';
	import { PrismicLink, PrismicRichText } from '@prismicio/svelte';
    import { inview } from 'svelte-inview';
	import Subscribe from './Subscribe.svelte';

    export let data;
    export let year;
    export let hideFooter;

    let showSubscribe: boolean = false;

    function toggleSubscribe(event: MouseEvent) {
        showSubscribe = !showSubscribe;

        setTimeout(() => {
            scrollToElement(document, event, false);
        }, 400);
    }
</script>

<footer class="main-footer"
    class:visually-hidden={hideFooter}
    use:inview
    on:inview_enter={hideMenu}
    on:inview_leave={showMenu}
>
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-12 col-lg-5">
                <div class="main-footer__content">
                    {#if prismic.isFilled.richText(data.content)}
                        <PrismicRichText field={data.content} />
                    {/if}

                    {#if prismic.isFilled.link(data.button)}
                        <div class="main-footer__button--container">
                            <PrismicLink field={data.button} class="btn main-footer__button">
                                {data.button.text}
                            </PrismicLink>
                        </div>
                    {/if}
                </div>

            </div>

            <div class="col-12 col-lg-5">
                <div class="row">
                    {#if data.addresses.length}
                        {#each data.addresses as item}
                            {#if prismic.isFilled.richText(item.address)}
                                <div class="col-6 col-lg-4">
                                    <address>
                                        <PrismicRichText field={item.address} />
                                    </address>
                                </div>
                            {/if}
                        {/each}
                    {/if}

                    {#if data.social_links.length}
                        <div class="col-6 col-lg order-3 order-lg-2">
                            <h6 class="main-footer__title">Follow Us</h6>

                            <ul class="main-footer__menu">
                                {#each data.social_links as item}
                                    <li>
                                        <PrismicLink field={item.url}>
                                            {item.title}
                                        </PrismicLink>
                                    </li>
                                {/each}
                            </ul>
                        </div>
                    {/if}
                </div>
            </div>
        </div>

        <div class="main-footer__bottom">
            <div class="row justify-content-between align-items-end">
                <div class="col-12 col-lg-5 order-2 order-lg-1">
                    <p class="main-footer__copyright">&copy;{year} Limegreentangerine. All rights reserved.</p>
                </div>

                <div class="col-12 col-lg-5 order-1 order-lg-2">
                    <div class="row justify-content-between align-items-end">
                        {#if data.legal_links.length}
                            <div class="col">
                                <ul class="main-footer__legal">
                                    {#each data.legal_links as item}
                                        <li>
                                            <PrismicLink field={item.link}>
                                                {item.title}
                                            </PrismicLink>
                                        </li>
                                    {/each}

                                    <li>
                                        <a href="/" on:click|preventDefault={toggleSubscribe}>Subscribe to our newsletter</a>
                                    </li>
                                </ul>
                            </div>
                        {/if}

                        <div class="col">
                            <div class="d-flex justify-content-end">
                                <button on:click={scrollToTop} class="main-footer__top">
                                    <span>Top</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<Subscribe show={showSubscribe} />
